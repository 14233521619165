// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/login.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-content[data-v-372aa773]{align-items:center;display:flex}[data-v-372aa773] h1{font-size:1.5rem;font-weight:700;margin-bottom:1.5rem;margin-top:1.5rem}[data-v-372aa773] .logo{max-width:7.8125rem}[data-v-372aa773] .btn-100{width:100%}.auth-container[data-v-372aa773]{background:linear-gradient(90deg,#fff,#fff 80%,hsla(0,0%,100%,0));display:flex;flex-direction:column;justify-content:center;padding:1rem;position:relative;width:100%;z-index:100}@media screen and (min-width:1024px){.auth-container[data-v-372aa773]{background:linear-gradient(90deg,#f7fafe,#f7fafe 80%,hsla(0,0%,100%,0))}}@media screen and (min-width:1216px){.auth-container[data-v-372aa773]{max-width:56.25rem;min-height:100vh;padding-left:11.25rem;padding-right:11.25rem}}@media (min-width:1920px){.auth-container[data-v-372aa773]{padding-left:15.625rem;padding-right:15.625rem;width:1200px}}[data-v-372aa773] .line{background:#d2dce3;height:1px;margin-bottom:2rem;margin-top:2rem}[data-v-372aa773] .bottom-text{font-size:.875rem;text-align:center}@media screen and (min-width:1216px){.image-right[data-v-372aa773]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top no-repeat;background-size:cover;height:100%;pointer-events:none;position:fixed;right:0;top:0;width:calc(100% - 37.5rem);z-index:2}}@media (min-width:1920px){.image-right[data-v-372aa773]{width:calc(100% - 1000px)}}.reg[data-v-372aa773]{font-size:.6875rem;margin-top:1rem;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
