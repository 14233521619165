
import pwaControl from '~/mixins/pwaControl'

export default {
  mixins: [pwaControl],
  head () {
    return {
      title: this.$t('title.login_layout'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.login_layout')
        }
      ],
      link: [
        {
          rel: 'preconnect',
          href: 'https://fonts.googleapis.com'
        },
        {
          rel: 'preconnect',
          href: 'https://fonts.gstatic.com'
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Nunito&display=swap'
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Nunito:wght@300;500;600;700&display=swap'
        },
      ]
    }
  },
  mounted () {
    if (!this.getCookie('first_page_visited')) {
      this.setCookie('first_page_visited', this.$route.path)
    }

    if ('utm_source' in this.$route.query && !this.getCookie('first_utm_source')) {
      this.setCookie('first_utm_source', this.$route.query.utm_source)
    }
  }
}

